<template>
  <el-dialog
    title="分类"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="所属类型" prop="tKindID">
        <el-select
          v-model="temp.tKindID"
          placeholder="请选择"
          @change="change_kinds"
        >
          <el-option label="请选择" value="0"> </el-option>
          <el-option
            v-for="item in optionsList_kinds"
            :key="item.id"
            :label="item.kName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名称" prop="tName">
        <el-input v-model="temp.tName"></el-input>
      </el-form-item>
      <el-form-item label="图标路径" prop="tPath">
        <Upload :imgList="imgAy" @uploadFiles="upload_Files"></Upload>
      </el-form-item>
      <el-form-item label="属性" prop="tFlag">
        <el-radio-group v-model="temp.tFlag" @change="change_radio">
          <el-radio label="1">列表</el-radio>
          <el-radio label="2">上报</el-radio>
          <el-radio label="3">单页</el-radio>
          <el-radio label="4">建议</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上报类型" prop="tOtherID" v-if="temp.tFlag == 2">
        <el-select
          v-model="temp.tOtherID"
          placeholder="请选择"
          @change="change_others"
        >
          <el-option label="请选择" value="0"> </el-option>
          <el-option
            v-for="item in optionsList_others"
            :key="item.id"
            :label="item.tName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单页" prop="tOtherID" v-if="temp.tFlag == 3">
        <el-select
          v-model="temp.tOtherID"
          placeholder="请选择"
          @change="change_others"
        >
          <el-option label="请选择" value="0"> </el-option>
          <el-option
            v-for="item in optionsList_articles"
            :key="item.id"
            :label="item.aTitle"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="tSort">
        <el-input v-model="temp.tSort"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Upload from "../components/Upload.vue";
export default {
  components: { Upload },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    row_type(val) {
      Object.assign(this.temp, val);
    },
    show(val, oVal) {
      if (val != oVal) {
        this.dialogVisible = val;
      }
      if (val) {
        this.upfun();
      }
      this.getList();
    },
  },
  data() {
    return {
      show_other: 0,
      imgAy: [],
      optionsList_articles: [],
      optionsList_kinds: [],
      optionsList_others: [],
      dialogVisible: false,
      temp: {
        id: "",
        tName: "",
        tFlag: "",
        tSort: 100,
        tKindID: "",
        tPath: "",
        tOtherID: "",
      },
      rules: {
        tName: [
          {
            required: true,
            message: "名称必填",
            trigger: "blur",
          },
        ],
        tFlag: [
          {
            required: true,
            message: "属性必填",
            trigger: "blur",
          },
        ],
        tSort: [
          {
            required: true,
            message: "排序必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    change_radio(val) {
      if (val == 1) {
        this.show_other = false;
      } else if (val == 2) {
        this.show_other = 2;
      } else if (val == 3) {
        this.show_other = 3;
      } else if (val == 4) {
        this.show_other = 4;
      }
    },
    upfun() {
      this.$nextTick(() => {
        if (this.temp.tPath.indexOf("http") > -1) {
          this.imgAy = [{ url: this.temp.tPath }];
        } else {
          this.imgAy = [];
        }
      });
    },
    upload_Files(ay) {
      // console.log(ay.imgs);
      let ay_img = ay.imgs;
      this.temp.tPath = ay_img[ay_img.length - 1];
    },
    change_others(val) {
      this.temp.tOtherID = val;
    },
    change_kinds(val) {
      this.temp.tKindID = val;
    },
    getList() {
      this.$api.article_kinds_list_all({ id: this.temp.id }).then((res) => {
        this.optionsList_kinds = res.data.rows_kinds;
        this.optionsList_others = res.data.rows_news_type;
        this.optionsList_articles = res.data.rows_pages;
      });
    },
    update() {
      this.$emit("update");
    },
    close() {
      this.$emit("close");
    },
    save() {
      // console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.$api.article_types_save(this.temp).then((res) => {
            this.close();
            this.update();
            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
